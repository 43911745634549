<template>
    <user>usuarios</user>
</template>

<script>
import user from '@/components/User/UserCrud';

export default {
    name: 'usuario',
    data() {
        return {};
    },
    components: {
        user
    }
};
</script>
