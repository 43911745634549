<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="valid && password!='' && confirmpassword!='' && password==confirmpassword"
                       rounded
                       small
                       color="error"
                       @click="save">
                    Guarda
                </v-btn>
            </v-toolbar>

        </v-card-title>

        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-container fill-height>
                    <v-flex xs12 pa-1>
                        <v-text-field prepend-inner-icon="mdi-lock"
                                      name="password"
                                      label="Password"
                                      :append-icon="
                                            show1 ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                      :type="show1 ? 'text' : 'password'"
                                      @click:append="show1 = !show1"
                                      required
                                      v-model="password"
                                      :rules="passwordRules"
                                      data-cy="joinPasswordField">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 pa-1>
                        <v-text-field prepend-inner-icon="mdi-lock"
                                      label="Confirma Password"
                                      :append-icon="
                                            show2 ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                      :type="show2 ? 'text' : 'password'"
                                      @click:append="show2 = !show2"
                                      required
                                      v-model="confirmpassword"
                                      :rules="passwordRules"
                                      data-cy="joinPasswordField"></v-text-field>
                    </v-flex>
                </v-container>
            </v-form>
        </v-card-text>

    </v-card>
</template>

<script>

    export default {
        name: 'ChangePasswordDialogo',
        data() {
            return {
                valid: false,
                loading: false,
                show1: false,
                show2: false,
                password: '',
                confirmpassword: '',
                passwordRules: [
                    v => !!v || 'Password is required',
                    v =>
                        v.length >= 6 || 'Password debe tener más de 6 caracteres'
                ]
            };
        },

        props: ['title'],

        watch: {
        },

        methods: {
            close() {
                setTimeout(() => {
                    this.$emit('closeDlg');
                }, 300);
            },
            save() {
                this.$emit('change', this.password);
                this.close();
            }
        },

        computed: {

        },
        components: {
        }
    };
</script>
