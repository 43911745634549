<template>
    <v-select :value="value"
              :dense="dense"
              :disabled="disabled"
              :items="items"
              item-text="name"
              item-value="id"
              attach
              chips
              label="Roles"
              @change="change"
              @input="update"
              multiple
              :menu-props="{ auto: true, botton: true, offsetY: true }">
    </v-select>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'selectRole',
        props: ['value', 'disabled', 'dense'],
        data: function () {
            return {};
        },

        computed: {
            ...mapState('role', ['items'])
        },
        methods: {
            ...mapActions('role', ['getAll']),

            update(newValue) {
                this.$emit('input', newValue);
            },

            change(value) {
                this.$emit('change', value);
            },

        },
        mounted() {
            this.getAll();
        },
    };
</script>
