<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn rounded
                       small
                       color="warning"
                       class="mr-3"
                       @click="dialog=true">
                    <v-icon>mdi-account-key</v-icon>
                </v-btn>
                <v-btn rounded
                       small
                       color="error"
                       @click="saveItem">
                    Guarda
                </v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap dense>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.userName"
                                          label="Usuario"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.apellido"
                                          label="Apellido"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md3>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md1>
                            <v-text-field v-model="item.abrv"
                                          label="Iniciales"></v-text-field>
                        </v-flex>

                        <v-flex xs12  sm6 v-if="!tieneClientes">
                            <selectRole v-model="item.detalles"
                                        @change="changeRoles"></selectRole>
                        </v-flex>

                        <v-flex xs12 v-if="tieneClientes">
                            <v-layout wrap dense>
                                <v-flex xs12 sm6>
                                    <selectRole v-model="item.detalles"
                                                @change="changeRoles">
                                    </selectRole>
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <selectCliente v-model="item.detalles1"
                                                   @change="changeClientes"
                                                   sm5></selectCliente>
                                </v-flex>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12 sm6 md1>
                            <v-checkbox v-model="item.emailConfirmed"
                                        class="pl-3"></v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <v-text-field v-model="item.email"
                                          label="Email"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md1>
                            <v-checkbox v-model="item.phoneNumberConfirmed"
                                        class="pl-3"></v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.phoneNumber"
                                          label="Telefono"></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md2>
                            <v-text-field type="number"
                                          v-model="item.accessFailedCount"
                                          label="Acc. inválidos"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md2>
                            <v-checkbox v-model="item.activo"
                                        label="Activo"></v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm6 md2>
                            <v-checkbox v-model="item.twoFactorEnabled"
                                        label="2 Valid."></v-checkbox>
                        </v-flex>

                        <v-flex xs12 sm6 md3>
                            <v-checkbox v-model="item.lockoutEnabled"
                                        label="Bloqueado"></v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm6 md3>
                            <v-text-field v-model="item.lockoutEnd"
                                          label="Bloqueado hasta"></v-text-field>
                        </v-flex>

                        <!--<v-flex xs12 sm6 md4> <v-text-field v-model="item.normalizedEmail" label="NormalizedEmail"></v-text-field></v-flex>
                        <v-flex xs12 sm6 md4> <v-text-field v-model="item.normalizedUserName" label="NormalizedUserName"></v-text-field></v-flex>
                        <v-flex xs12 sm6 md4> <v-text-field v-model="item.passwordHash" label="PasswordHash"></v-text-field></v-flex>-->
                        <!--<v-flex xs12 sm6 md4> <v-text-field v-model="item.telefono" label="Telefono"></v-text-field></v-flex>-->
                        <!--<v-flex xs12 sm6 md4> <v-text-field v-model="item.securityStamp" label="SecurityStamp"></v-text-field></v-flex>
                        <v-flex xs12 sm6 md4> <v-text-field v-model="item.concurrencyStamp" label="ConcurrencyStamp"></v-text-field></v-flex>
                        <v-flex xs12 sm6 md4> <v-text-field v-model="item.token" label="Token"></v-text-field></v-flex>-->
                        <!--<v-flex xs12 sm6 md4> <v-text-field v-model="item.userRoles" label="UserRoles"></v-text-field></v-flex>-->
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>
        <template>
            <v-dialog v-model="dialog"
                      max-width="450px"
                      persistent>
                <changePasswordDialog title="Ingrese nueva contraseña"
                                      @closeDlg="dialog=false"
                                      @change="changePassword">
                </changePasswordDialog>
            </v-dialog>
        </template>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import changePasswordDialog from '@/components/Accounts/ChangePasswordDialog'
    import selectRole from '@/components/Selects/selectRole';
    import selectCliente from '@/components/Selects/selectClientes';

    export default {
        name: 'userDialogo',
        data() {
            return {
                dialog: false
            };
        },

        computed: {
            ...mapState('user', ['item']),

            tieneClientes() {
                if (this.item.detalles == null)
                    return false;

                let ret = this.item.detalles.filter(x => x == 5 || x == 6 || x == 8).length;
                return ret > 0;
            },

            formTitle() {
                return this.item.id === 0 ? 'Nuevo usuario' : 'Edita usuario';
            }
        },

        methods: {
            ...mapActions('account', ['resetSc', 'logout']),
            ...mapActions('user', ['closeDialog', 'saveItem', 'setDetalle', 'setDetalle1', 'setPassword']),

            close() {
                this.closeDialog();
            },

            changeRoles(value) {
                var roles = [];

                value.forEach(item =>
                    roles.push({ userId: this.item.id, roleId: item })
                );

                this.item.userRoles = roles;
            },

            changeClientes(value) {
                var clientes = [];

                value.forEach(item =>
                    clientes.push({ ApplicationUserId: this.item.id, clienteId: item })
                );
                this.item.userClientes = clientes;
            },

            changePassword(pass) {
                this.setPassword(pass);

                if (this.item.id != 0)
                    this.resetSc({
                        userId: this.item.id,
                        password: pass
                    });

            }
        },

        mounted() {
        },

        components: {
            changePasswordDialog,
            selectRole,
            selectCliente
        }
    };
</script>
